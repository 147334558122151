<!-- =========================================================================================
  File Name: CourseList.vue
  Description: Course List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/partner/pixinvent
========================================================================================== -->

<template>

  <div id="page-partner-list">

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown class="cursor-pointer" vs-trigger-click>
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }} - {{
                  partnersData.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : partnersData.length
                }} of {{ partnersTotal }}</span>
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input @input="updateSearchQuery" class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                  placeholder="Search..." v-model="searchQuery"/>
        <vs-button @click="gridApi.exportDataAsCsv()" class="mb-4 md:mb-0">Export as CSV</vs-button>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        :animateRows="true"
        :columnDefs="columnDefs"
        :components="components"
        :defaultColDef="defaultColDef"
        :enableRtl="$vs.rtl"
        :floatingFilter="true"
        :gridOptions="gridOptions"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowData="partnersData"
        :suppressPaginationPanel="true"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        colResizeDefault="shift"
        ref="agGridTable">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" v-model="currentPage"/>

    </div>
  </div>

</template>

<script>
import {AgGridVue} from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

// Store Module
// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue';
import CellRendererActions from './cell-renderer/CellRendererActions.vue';
import CellRendererCustomer from './cell-renderer/CellRendererCustomer.vue';

export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererActions,
    CellRendererCustomer,
  },
  data() {
    return {

      partnersData: [],
      partnersTotal: 0,

      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true
        },
        {
          headerName: 'Name',
          field: 'name',
          filter: true,
          width: 310,
        },
        {
          headerName: 'Owner',
          field: 'owner_id',
          filter: true,
          width: 450,
          cellRendererFramework: 'CellRendererCustomer',
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions,
      },
      totalPages: 0,
    };
  },
  watch: {},
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
        this.loadCourseBuffer(val);
      },
    },
  },
  methods: {
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = {type: 'equals', filter: val};
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    loadCourseBuffer(page = 0) {

      if (page === 0) {
        page = this.currentPage;
      }

      this.$http.get(`partners?amount=${this.paginationPageSize}&page=${page}`)
        .then(response => {

          if (response.data.data) {
            this.partnersData = response.data.data;
          }

          this.partnersData = this.partnersData.map(partner => {
            return partner;
          });

          this.gridApi.sizeColumnsToFit();
        })
        .catch(exception => {

        });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
  created() {

    this.$http.get(`partners/counts?amount=${this.paginationPageSize}`)
      .then(response => {
        if (response.data.data) {

          const data = response.data.data;

          if (data.pages) {
            this.totalPages = data.pages;
            this.partnersTotal = data.total;
          }

        }
      })
      .catch(exception => {

      });

  },
};

</script>

<style lang="scss">
#page-partner-list {
  .partner-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
